h3 {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .widget-tabs {
    padding: 30px 20px;
    margin: 15px 0px;
  }
  
  .home-widget .widget-tabs {
    padding: 30px 20px 0;
    height: 100%;
    margin-bottom: 0px;
  }
  
  @media (max-width: 1900px) {
    .col-66 {
      width: 50%;
      flex: auto;
    }
  }
  
  .ww1 img {
    width: 25px;
  }
  
  .home-widget .box-h:hover {
    background: transparent;
  }
  
  .ww1 {
    min-width: 21%;
  }
  
  .home-widget .sp2 {
    width: 5% !important;
    min-width: auto;
  }
  
  @media (min-width: 1600px) and (max-width: 1800px) {
    .ww1 {
      min-width: 18%;
    }
  
    h3 {
      font: normal normal bold 1.6rem/2rem "F37 Moon Demi";
    }
  }
  
  @media (min-width: 1100px) and (max-width: 1600px) {
    .ww1 {
      min-width: 30%;
    }
  
    h3 {
      font: normal normal bold 1.4rem/2rem "F37 Moon Demi";
    }
  }
  .btn {
    border: 0px;
  }
  .edit {
    cursor: pointer;
  }
  .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    padding: 4px;
  }
  .dataTables_length select option {
    color: #000;
  }
  .dataTables_filter input {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    margin-left: 3px;
  }
  .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 2px;
    background: rgba(0, 0, 0, 0);
  }
  .dataTables_paginate .paginate_button.current,
  .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    background: #28a745;
  }
  button.btn.btn-success {
    background: #28a745 !important;
  }
  .filter-table th{position: relative;cursor: pointer;}
  /* .filter-table th::before, .filter-table th::after{position: absolute;color: #FFF;  font-family: FontAwesome;
      display: block;
      opacity: .3;
      right: 10px;
      line-height: 2px;
      font-size: 15px;}
  .filter-table th::before{bottom: 50%; content: "\f0de";}
  .filter-table th::after{    top: 50%;content: "\f0dd";} */ 
  .min-h-540 tbody{
    max-height: calc(100vh - 370px);
    min-height: calc(100vh - 370px);
  }
 
  .custom-width-nursing-grid-col thead tr th:nth-child(1), .custom-width-nursing-grid-col tbody tr td:nth-child(1){width: 50px; text-align: center;}
  .custom-width-nursing-grid-col thead tr th:nth-child(5), .custom-width-nursing-grid-col tbody tr td:nth-child(5){width: 70px; text-align: center;}
 
.login-screen .otp input {
    height: 50px;
    flex: 1 0 0%;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
}
.login-screen .otp span {
    padding: 0px 7px;
}
.hover-text:hover {
    text-decoration: underline;
}
.max-width-300{max-width: 600px; padding: 30px;background-color: #FFF;}  
.login-screen input{height: 50px;}
.h-100vh{min-height: 100vh;}
.h-100{height:100%;}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button.btm{height: 45px;
    margin-bottom: 28px;
    border-radius: 0.1875rem !important;display: block;
    width: 100%;
    color: #FFF;}
    input.form-check-input.h-16{height: 16px;}
    input.form-check-input.h-16.show {
        display: block;
    }
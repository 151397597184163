h3 {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .widget-tabs {
    padding: 30px 20px;
    margin: 15px 0px;
  }
  
  .home-widget .widget-tabs {
    padding: 30px 20px 0;
    height: 100%;
    margin-bottom: 0px;
  }
  
  @media (max-width: 1900px) {
    .col-66 {
      width: 50%;
      flex: auto;
    }
  }
  
  .ww1 img {
    width: 25px;
  }
  
  .home-widget .box-h:hover {
    background: transparent;
  }
  
  .ww1 {
    min-width: 21%;
  }
  
  .home-widget .sp2 {
    width: 5% !important;
    min-width: auto;
  }
  
  @media (min-width: 1600px) and (max-width: 1800px) {
    .ww1 {
      min-width: 18%;
    }
  
    h3 {
      font: normal normal bold 1.6rem/2rem "F37 Moon Demi";
    }
  }
  
  @media (min-width: 1100px) and (max-width: 1600px) {
    .ww1 {
      min-width: 30%;
    }
  
    h3 {
      font: normal normal bold 1.4rem/2rem "F37 Moon Demi";
    }
  }
  .btn {
    border: 0px;
  }
  .edit {
    cursor: pointer;
  }
  .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    padding: 4px;
  }
  .dataTables_length select option {
    color: #000;
  }
  .dataTables_filter input {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    margin-left: 3px;
  }
  .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 2px;
    background: rgba(0, 0, 0, 0);
  }
  .dataTables_paginate .paginate_button.current,
  .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    background: #28a745;
  }
  button.btn.btn-success {
    background: #28a745 !important;
  }
  .filter-table th{position: relative;cursor: pointer;}
  /* .filter-table th::before, .filter-table th::after{position: absolute;color: #FFF;  font-family: FontAwesome;
      display: block;
      opacity: .3;
      right: 10px;
      line-height: 2px;
      font-size: 15px;}
  .filter-table th::before{bottom: 50%; content: "\f0de";}
  .filter-table th::after{    top: 50%;content: "\f0dd";} */
  .custom-width-col{min-width: 5020px;}
  .min-h-580 tbody{
    max-height: calc(100vh - 320px);
    min-height: calc(100vh - 320px);
  }
  /* .custom-width-col thead tr th:nth-child(1), .custom-width-col tbody tr td:nth-child(1){width: 39px; text-align: center;}
  .custom-width-col thead tr th:nth-child(2), .custom-width-col tbody tr td:nth-child(2){width: 57px; text-align: center;}
  .custom-width-col thead tr th:nth-child(3), .custom-width-col tbody tr td:nth-child(3){width: 106px;}
  .custom-width-col thead tr th:nth-child(4), .custom-width-col tbody tr td:nth-child(4){width: 136px;}
  .custom-width-col thead tr th:nth-child(5), .custom-width-col tbody tr td:nth-child(5){width: 136px;}
  .custom-width-col thead tr th:nth-child(6), .custom-width-col tbody tr td:nth-child(6){width: 176px;}
  .custom-width-col thead tr th:nth-child(7), .custom-width-col tbody tr td:nth-child(7){width: 168px;} */

  .table-wrapper {
 
    border: 1px solid var(--borderColor);
    overflow: auto;
  }
  
  table.border-bottom-table  {
    border-spacing: 0;
  }
  
  .border-bottom-table  thead th{
    font-size: 14px;
    line-height: 16px;
   font-weight: 500;
   width: 105px;
   word-break: break-word;
  }
  
  .border-bottom-table tbody {
    font-size: 14px;
  }
  
  .border-bottom-table th, .border-bottom-table td {
    padding: 7px 7px;
    /* white-space: nowrap;
    border-bottom: 1px solid var(--borderColor); */
  }
  
  .border-bottom-table tr:last-child td {
    border-bottom: none;
  }
  
  
  /*
  STICKY ROW
  Normal css box-shadow works for the header as it is a single html element
  */
  
  .border-bottom-table thead tr:nth-child(1) {
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--stickyBackground);
    box-shadow: 0 0 6px rgba(0,0,0,0.25);
  }
  
  
  /*
  STICKY COLUMN
  Avoid undesirable overlapping shadows by creating a faux shadow on the ::after psudo-element instead of using the css box-shadow property.
  */
 


  .border-bottom-table  th:nth-child(1), .border-bottom-table td:nth-child(1) { position: sticky; left: 0;      z-index: 1;  width: 50px; min-width: 50px; }
  .border-bottom-table  th:nth-child(2), .border-bottom-table td:nth-child(2) { position: sticky; left: 50px;   z-index: 1;  width: 65px; min-width: 65px; }
  .border-bottom-table  th:nth-child(3), .border-bottom-table td:nth-child(3) { position: sticky; left: 115px;  z-index: 1; width: 110px; min-width: 110px;}
  .border-bottom-table th:nth-child(4), .border-bottom-table td:nth-child(4) { position: sticky; left: 225px;  z-index: 1; width: 135px; min-width: 135px;}
  .border-bottom-table  th:nth-child(5), .border-bottom-table td:nth-child(5) { position: sticky; left: 360px;  z-index: 1; width: 135px; min-width: 135px;}
  .border-bottom-table  th:nth-child(6), .border-bottom-table td:nth-child(6) { position: sticky; left: 495px;  z-index: 1; width: 175px; min-width: 175px;}
  .border-bottom-table  th:nth-child(7), .border-bottom-table td:nth-child(7) { position: sticky; left: 670px;  z-index: 1; width: 165px; min-width: 165px;}
  .border-bottom-table  th:nth-child(8), .border-bottom-table td:nth-child(8) { position: sticky; left: 835px;  z-index: 1;  width: 105px; min-width: 105px;}  
  
  .border-bottom-table td,  .border-bottom-table th {
    padding: 7px 7px;
    border-bottom-width: 1px;
}
.border-bottom-table  tbody{  display: table-caption;
  min-height: calc(100vh - 320px);} 
  .table-wrapper{max-height: calc(100vh - 320px);
    min-height: calc(100vh - 320px);}
    



    .table-wrapper::-webkit-scrollbar {
      width: 5px; height: 5px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
      background: #8d8d8d;
      border-radius: 30px;
  }
  .table-wrapper::-webkit-scrollbar-track {
    background: #deebfd;
    border-radius: 30px;
}
.css-1nmdiq5-menu {
  z-index: 3 !important;
}

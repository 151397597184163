.boxlink{color: #000; text-decoration: none;}
.boxlink:hover{color: #000;}
.tooltip {
    position: static;
    display: inline-block;
    border-bottom: 0px;
    opacity: 1;
    padding: 0;
    cursor: alias;
}

.tooltip .tooltiptext {
    display: none;
  visibility: hidden;
  width: 120px;
  background-color: #28A745;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 3px 6px; width: auto; font-size: 12px; font-weight: 500;white-space: nowrap;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0px);
}

.tooltip:hover .tooltiptext {
  visibility: visible;display: block;
}

h3 {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .widget-tabs {
    padding: 30px 20px;
    margin: 15px 0px;
  }
  
  .home-widget .widget-tabs {
    padding: 30px 20px 0;
    height: 100%;
    margin-bottom: 0px;
  }
  
  @media (max-width: 1900px) {
    .col-66 {
      width: 50%;
      flex: auto;
    }
  }
  
  .ww1 img {
    width: 25px;
  }
  
  .home-widget .box-h:hover {
    background: transparent;
  }
  
  .ww1 {
    min-width: 21%;
  }
  
  .home-widget .sp2 {
    width: 5% !important;
    min-width: auto;
  }
  
  @media (min-width: 1600px) and (max-width: 1800px) {
    .ww1 {
      min-width: 18%;
    }
  
    h3 {
      font: normal normal bold 1.6rem/2rem "F37 Moon Demi";
    }
  }
  
  @media (min-width: 1100px) and (max-width: 1600px) {
    .ww1 {
      min-width: 30%;
    }
  
    h3 {
      font: normal normal bold 1.4rem/2rem "F37 Moon Demi";
    }
  }
  .btn {
    border: 0px;
  }
  .edit {
    cursor: pointer;
  }
  .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    padding: 4px;
  }
  .dataTables_length select option {
    color: #000;
  }
  .dataTables_filter input {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    margin-left: 3px;
  }
  .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 2px;
    background: rgba(0, 0, 0, 0);
  }
  .dataTables_paginate .paginate_button.current,
  .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    background: #28a745;
  }
  button.btn.btn-success {
    background: #28a745 !important;
  }
  .filter-table th {
    position: relative;
    cursor: pointer;
  }
  /* .filter-table th::before,
    .filter-table th::after {
      position: absolute;
      color: #fff;
      display: block;
      opacity: 0.3;
      right: 10px;
      line-height: 8px;
      font-size: 12px;
    }
    .filter-table th::before {
      bottom: 50%;
      content: "\2BC5";
    }
    .filter-table th::after {
      top: 50%;
      content: "\2BC6";
    } */
  
  .statusdrop {
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    overflow-y: hidden;
  }
  .statusIcon {
    width: 20px;
  }
  .active-class {
    background-color: aqua;
  }
  .statusTD {
    .ant-switch.ant-switch-checked {
      background: rgb(40, 167, 69) !important;
    }
    .ant-switch {
      background: rgb(190, 31, 31) !important;
    }
  }
  .dt-pi .react-datepicker-wrapper input {
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    height: 43px;
    border-radius: 4px !important;
    background: transparent !important;
    height: 35px !important;
    min-height: 35px !important;
    border-color: #828282 !important;
    border-radius: 5px px !important;
    font-size: 12px !important;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
  }
   .dataTables_filter input {
    border: 1px solid #aaa;
    border-radius: 3px 0px 0px 3px;height: 34px;
    
  }
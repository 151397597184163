.card .card-body .form-group label {
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: top;
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  .card .card-body .form-control,
  .card .card-body .form-select,
  .asColorPicker-input,
  .dataTables_wrapper select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-search__field,
  .typeahead,
  .tt-query,
  .tt-hint {
    border: 1px solid #2c2e33;
    /* height: calc(2.25rem + 2px); */
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    background-color: #2a3038 !important;
    border-radius: 2px;
    color: #ffffff !important;
  }
  .card .card-body .form-group {
    margin-bottom: 1rem;
  }
  .white-text-dark-mode,
  .modal-title,
  .form-label {
    color: #f3f3f3 !important;
  }
  .form-select.white,
  .col .form-control {
    color: #fff !important;
  }
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    color: #fff !important;
  }
  .card .card-body .form-select {
    background-image: url(../../assets/img/down-arrow.png);
  }
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  .checkbox-group {
    display: flex;
    flex-wrap: wrap; /* Allow checkboxes to wrap to the next row */
    gap: 16px; /* Adjust the gap between checkboxes */
  }
  
  /* Style for each checkbox item */
  .checkbox-item {
    flex: 0 0 calc(25% - 16px); /* Each checkbox takes up 25% of the container width with a gap of 16px */
    box-sizing: border-box;
  }
  
  .custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #3498db;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
    vertical-align: middle;
  }
  
  .custom-checkbox:checked {
    background-color: rgb(0, 144, 231);
    border-color: rgb(0, 144, 231);
  }
  
  .custom-checkbox:checked::before {
    content: "\2713";
    display: block;
    text-align: center;
    line-height: 13px;
    color: #000;
    font-weight: bold;
  }
  /* Style for the radio button container */
  .radio-container {
    position: relative;
    padding-left: 30px; /* Adjust based on your design */
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Hide the actual radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
  }
  
  /* Style the radio checkmark */
  .radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 4px solid rgb(42, 48, 56); /* Change border color */
    border-radius: 50%;
  }
  
  /* Change the radio button color when checked */
  .radio-container input:checked + .radio-checkmark {
    background-color: rgb(0, 144, 231); /* Change to the desired color */
  }
  
  /* Adjust the appearance of the checked state */
  .radio-container input:checked + .radio-checkmark::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff; /* Color of the inner checked circle */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  .custom-select .select__control {
    color: blue !important; /* Use !important to ensure it overrides existing styles */
  }
  
  /* Optional: Override the color of the selected option */
  .custom-select .select__single-value {
    color: blue !important;
  }
  .css-1nmdiq5-menu {
    background-color: #1b1b1b !important;
  }
  
  .dropdown-menu.show {
    background-color: #000 !important;
  }
  .css-1n6sfyn-MenuList > div,
  .css-qr46ko > div {
    font-size: 14px;
  }
  /* .css-1n6sfyn-MenuList > div:active,
  .css-1n6sfyn-MenuList > div:hover,
  .css-qr46ko > div:active,
  .css-qr46ko > div:hover {
    background-color: #000 !important;
    color: #fff !important;
  } */
  .css-d7l1ni-option,
  .css-d7l1ni-option {
    background-color: transparent;
  }
  .css-d7l1ni-option {
    background-color: #000 !important;
  }
  .css-1dimb5e-singleValue {
    color: #9c9c9c !important;
  }
  .blue-b.ant-switch.ant-switch-checked {
    background: rgb(49 83 111) !important;
  }
  .grey-color {
    color: #838383;
  }
  .no-wrap {white-space: nowrap;}
  .card .card-body  .sm-transparent-input input.form-control {
    text-align: center;
    width: 75px;
    /* height: 35px; */
    /* margin: 0 auto; */
    /* color: #000; */
    font-size: 14px;
    font-family: Sailec;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 24px;
    letter-spacing: 0.52px;
    padding: 0.375rem 0.25rem !important;
    /* border: 1px solid #ced4da !important; */
    /* background: #8a5555 !important; */
    /* color: #FFF !important; */
    appearance: none;
}
.vertical-aling-middle{vertical-align: middle;}
.mx-w-340{max-width: 340px;}
.cursor-pointer{cursor: pointer;}
.accordion-item {
  margin-bottom: 15px; background: transparent;
}
.accordion-item:not(:first-of-type) {
  border: 1px solid rgba(0, 0, 0, .125);
  /* border-top: 0; */
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button {
  font-size: 1.25rem !important;
}
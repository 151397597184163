
.modal-header .btn-close{margin-left: 2em;}
.f-10{font-size: 11px;}
.f-10 thead th{background: 
    #E8E8E8;}
    .box-heading {color: #4B4D4D;font-size: 16px;font-family: F37 Moon;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.25px;}
    .border-btn{padding: 6px 20px;justify-content: center;align-items: center;gap: 10px;border-radius: 8px;border: 1px solid;cursor:pointer;margin: 5px;}
    .width-1{width:330px;}
    .width-2{width:calc(100% - 330px)}
    .blue-b{color: #458FFF; border-color: #458FFF;}
    .purple-b{color: #9E00FF; border-color: #9E00FF;}
    .green-b{color: #28A745; border-color:#28A745; background: #fff; text-decoration: none;}
    th.coll { white-space: nowrap;color: #4B4D4D;font-size: 12px;font-weight: 500;line-height: 18px; padding: 0px 10px;border: 0;}
.activity-tabel td{padding: 5px;    vertical-align: middle;font-size:12px;}
.v-m{    vertical-align: middle;}
    .transparent-btn{border-radius: 13px;box-shadow: none;cursor:pointer;
        border: 1px solid #D9D9D9;
        text-transform: capitalize;
        font-size: 12px;
        font-family: Sailec;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 15px 10px;} 
    .grey-shade{background: #EFEFEF;
        color: #4B4D4D;box-shadow: none;}
    
    
    .spacing-table {
      
      border-collapse: separate;
      border-spacing: 0 5px; 
    }
    .spacing-table th {
      padding: 0px 10px 5px;
      vertical-align: middle;
    }
    .spacing-table td {
      border-width: 1px 0;
      border-color: #D9D9D9;
      border-style: solid;
      padding: 5px 10px;
    }
    .spacing-table td.noborder{border: 0;}
    .spacing-table td:first-child {
      border-left-width: 1px;
      border-radius: 13px 0 0 13px;
    }
    .spacing-table td:last-child {
      border-right-width: 1px;
      border-radius: 0 13px 13px 0;
    }
    .spacing-table thead {
      display: table;
      /* table-layout: fixed; */
      width: 100%;
    }
    .spacing-table tbody {    margin-top: -12px;
      display: table;
      /* table-layout: fixed;  */
      width: 100%;
      border-spacing: 0 10px;
    }
    #anticipatedChartt {
      height: 100%;
      width: 100%;
    }
    td.f-row {
        display: flex;
        align-items: center;
    }
    .green-b.active, .green-b:hover{background-color: #28A745; color:#FFF; }
    .button-shadow:hover{box-shadow: 0 8px 9px -4px rgba(0,0,0,0.15),0 4px 18px 0 rgba(0,0,0,0.1);}
    .button-disable:disabled{background-color: #EFEFEF; border: 1px solid #D9D9D9;}
    .tb2 tbody tr:nth-last-child(1), .tb2 tbody tr:nth-last-child(1) td{border: 0px !important;}
    .table th {
      font-weight: 500;
  }
  .tb2 td {
    padding: 2px;
    vertical-align: middle;
}
.h-50p{height: 50%;}
.bd-2{border-top: 5px solid #eeeeee;}
.pointer{cursor: pointer;}
.overlfow-auto {
  overflow: auto;
}
.h-607{min-height: 607px;}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active-data-point {
  fill: #413f42; /* Change this color to the desired active color */
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.sm-logo {
  display: none;
}

.ant-layout-sider-collapsed .sm-logo {
  display: block;
}

.ant-layout-sider-collapsed .lg-logo {
  display: none;
}

.logo {
  background-color: #191c24;
  margin: 0;
  height: 64px;
}

main {
  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;
}

main::-webkit-scrollbar {
  width: 7px;
}

main::-webkit-scrollbar-track {
  background: #3b3b3b;
  border-radius: 30px;
}

main::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 30px;
}

.ant-menu-title-content {
  font-size: 16px;
}

.ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
}

.ant-layout-header p {
  line-height: 20px;
  font-size: 13px;
}

.ant-layout-header div .badge {
  top: 16px;
  right: -6px;
}

.error {
  color: red;
  font-size: 12px;
}

/* .main-sect {
  background: black !important;
} */
header.main-sect {
  background: #191c24 !important;
}
/* .ant-menu-item-selected {
  background-color: #B039CC !important;
} */
/* :where(.css-dev-only-do-not-override-1adbn6x).ant-layout .ant-layout-sider,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark > .ant-menu,
:where(.css-1adbn6x).ant-layout .ant-layout-sider,
:where(.css-1adbn6x).ant-menu-dark,
:where(.css-1adbn6x).ant-menu-dark > .ant-menu {
  background: #191c24 !important;
} */
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark
  > .ant-menu
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark:not(
    .ant-menu-horizontal
  )
  .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-submenu:hover,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu
  .ant-menu-submenu-inline:hover,
:where(.css-1adbn6x).ant-menu-dark .ant-menu-item-selected,
:where(.css-1adbn6x).ant-menu-dark > .ant-menu .ant-menu-item-selected,
:where(.css-1adbn6x).ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-1adbn6x).ant-menu-dark
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-1adbn6x).ant-menu .ant-menu-submenu:hover,
:where(.css-1adbn6x).ant-menu .ant-menu-submenu-inline:hover {
  background-color: #1677ff;
  background: #0f1015;
  position: relative;
  color: #6c7293;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  /* 
  border-radius: 0px 100px 100px 0px; */
  color: #fff;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu
  .ant-menu-item
  .ant-menu-item-icon,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu
  .ant-menu-submenu-title
  .ant-menu-item-icon {
  line-height: 1;
  background: rgba(108, 114, 147, 0.2);
  width: 31px;
  height: 31px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.65);
  padding: 5px;
}
:where(.css-1adbn6x).ant-menu .ant-menu-item .ant-menu-item-icon,
:where(.css-1adbn6x).ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  line-height: 1;
  background: rgba(108, 114, 147, 0.2);
  width: 31px;
  height: 31px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.65);
  padding: 5px;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline.ant-menu-root
  .ant-menu-item
  > .ant-menu-title-content,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  display: inline-block;
  font-size: 0.89rem;
  line-height: 1;
  vertical-align: middle;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline-collapsed
  > .ant-menu-item,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  inset-inline-start: 0px;
  padding-inline: 20px;
  text-overflow: clip;
  padding-top: 4px;
}
.dropdown-menu.show {
  background-color: #000;
  width: 100%;
  padding: 0;
}
.dropdown-menu.show {
  background-color: #000 !important;
}
.l-menu .btn {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
}
button.add.btn.btn-primary.todo-list-add-btn {
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1;
  border-radius: 0.1875rem !important;
}
.card {
  background: #191c24 !important;
  color: #fff;
}
.table th,
.table td {
  color: #ffffff !important;
}
.btn {
  border: 0;
}
.dpdown .dropdown-menu {
  min-width: 220px;
  position: absolute;
  font-size: 0.9rem;
  margin-top: 0;
  top: 48px;
  right: 0;
  left: auto;
  -webkit-box-shadow: 0px 0px 35px -3px black;
  -moz-box-shadow: 0px 0px 35px -3px black;
  box-shadow: 0px 0px 35px -3px black;
}
.dpdown .dropdown-item.active,
.dpdown .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: transparent !important;
}
.dpdown .dropdown-item {
  color: #ccc;
  color: #ccc;
  padding: 10px 15px;
  margin: 0;
  line-height: 20px;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark.ant-menu-inline
  .ant-menu-sub.ant-menu-inline,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark
  > .ant-menu.ant-menu-inline
  .ant-menu-sub.ant-menu-inline,
:where(.css-1adbn6x).ant-menu-dark.ant-menu-inline
  .ant-menu-sub.ant-menu-inline,
:where(.css-1adbn6x).ant-menu-dark
  > .ant-menu.ant-menu-inline
  .ant-menu-sub.ant-menu-inline {
  background: #191c24 !important;
}
.loader {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ant-switch.ant-switch-checked {
  background: rgb(40, 167, 69) !important;
}

.PhoneInputInput {
  background: transparent !important;
  border: 0 !important;
  color: inherit !important;
  box-shadow: none !important;
  outline: none !important;
}
input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional */
}

.apexcharts-gridline {
  stroke-opacity: 0.3 !important; /* Adjust the opacity */
}
.css-b62m3t-container{
  flex: 1 !important;
}


.menu-top {
  top: auto !important;
  bottom: 100% !important;
}

.menu-bottom {
  top: 100% !important;
  bottom: auto !important;
}
.multiSelectContainer ul{
  max-height: fit-content !important;
}
.searchWrapper{
  padding:2px 10px !important;
}


.dragging tbody,
.dragging td,
.dragging tfoot,
.dragging th,
.dragging thead,
.dragging tr {
  border-color: transparent !important;
  /* Remove border color when dragging */
}

span.shwmore {
  color: #03A9F4;
  /* font-weight: 500; */
  cursor: pointer;
  /* font-size: 14px; */
  margin-left: 5px;
}
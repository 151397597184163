/*
Name: 			main
Author: 		Sumit Sharma
Version: 		1.0.0
*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
   @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap'); */
:root {
  --cf-black-bg: #000;
  --cf-grey-bg: #f2f3f3;
  --cf-white-bg: #fff;
  --cf-light-bg: #f9f9f9;
  --cf-orange-bg: #ffa800;
  --cf-green-bg: #28a745;
  --cf-blue-bg: #458fff;

  --cf-color-white: #fff;
  --cf-color-black: #000;
  --cf-color-orange: #f5720e;
  --cf-color-grey: #868686;
  --cf-orange-color: #ffa800;
  --cf-color-blue: #458fff;
  --cf-color-green: #28a745;
  --cf-color-purple: #9e00ff;
  --cf-color-red: #ff0000;

  /* --cf-fp: 'Kanit', sans-serif;
     --cf-fs:  'Poppins', sans-serif; */
  --cf-fp: "F37 Moon Demi";
  --cf-fs: "Sailec";

  --cf-gap-150: 150px;
  --cf-gap-110: 110px;
  --cf-gap-100: 100px;
  --cf-gap-90: 90px;
  --cf-gap-80: 80px;
  --cf-gap-70: 70px;
  --cf-gap-60: 60px;
  --cf-gap-50: 50px;
  --cf-gap-40: 40px;
  --cf-gap-30: 30px;
  --cf-gap-20: 20px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: var(--cf-fs);
  color: #404041;
  background: #eeeeee;
}

/* ----- change body color by sumit on 16may ---------------------- */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.primary-font {
  font-family: var(--cf-fp);
}

.secondary-font {
  font-family: var(--cf-fs);
}

a {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 1400px) {
  .container {
    max-width: 96%;
  }
}

/* @media (min-width: 1900px){.container{ max-width: 1712px;}} */
@media (min-width: 1900px) {
  .container {
    max-width: 98%;
  }
}

@media (max-width: 1400px) {
  .container {
    max-width: 97%;
  }
}

h1 {
  font: normal normal 700 2.8rem/3.5rem "F37 Moon Demi";
}

h2 {
  font: normal normal 700 2.5rem/3.2rem "F37 Moon Demi";
}

h4 {
  font: normal normal bold 1.6rem/2rem "F37 Moon Demi";
}

h3 {
  font: normal normal bold 1.8rem/2rem "F37 Moon Demi";
}

h5 {
  font: normal normal bold 1.1rem/2rem "F37 Moon Demi";
}

h6 {
  font: normal normal bold 1.1rem/2.3rem "F37 Moon Demi";
}

p {
  font: normal normal normal 1rem/1.4rem "Sailec";
}

.big-para {
  font: normal normal normal 1.4rem/2rem "F37 Moon Demi";
}

.big-para-list {
  font: normal normal normal 1.2rem/1.8rem "F37 Moon Demi";
}

.dark--bg {
  background-color: var(--cf-black-bg);
}

.white--bg {
  background-color: var(--cf-white-bg);
}

.dark--grey {
  background-color: var(--cf-grey-bg);
}

.light--grey {
  background-color: var(--cf-light-bg);
}

.orange-bg {
  background-color: var(--cf-orange-bg);
}

.white-bg {
  background-color: var(--cf-white-bg);
}

.ptb--110 {
  padding-top: var(--cf-gap-110);
  padding-bottom: var(--cf-gap-110);
}

.ptb--100 {
  padding-top: var(--cf-gap-100);
  padding-bottom: var(--cf-gap-100);
}

.ptb--90 {
  padding-top: var(--cf-gap-90);
  padding-bottom: var(--cf-gap-90);
}

.ptb--80 {
  padding-top: var(--cf-gap-80);
  padding-bottom: var(--cf-gap-80);
}

.ptb--70 {
  padding-top: var(--cf-gap-70);
  padding-bottom: var(--cf-gap-70);
}

.ptb--60 {
  padding-top: var(--cf-gap-60);
  padding-bottom: var(--cf-gap-60);
}

.ptb--50 {
  padding-top: var(--cf-gap-50);
  padding-bottom: var(--cf-gap-50);
}

.ptb--40 {
  padding-top: var(--cf-gap-40);
  padding-bottom: var(--cf-gap-40);
}

.ptb--30 {
  padding-top: var(--cf-gap-30);
  padding-bottom: var(--cf-gap-30);
}

.ptb--20 {
  padding-top: var(--cf-gap-20);
  padding-bottom: var(--cf-gap-20);
}

.mt--150 {
  margin-top: var(--cf-gap-150);
}

.my--minus-70 {
  margin-top: -70px;
  margin-bottom: -70px;
}

.mt--70 {
  margin-top: var(--cf-gap-70);
}

.mt--40 {
  margin-top: var(--cf-gap-40);
}

.color-white {
  color: var(--cf-color-white);
}

.color-black {
  color: var(--cf-color-black);
}

.color-orange {
  color: var(--cf-orange-color);
}

.color-blue {
  color: var(--cf-color-blue);
}

.blue-color {
  color: var(--cf-color-blue);
}

.color-green {
  color: var(--cf-color-green);
}

.color-purple {
  color: var(--cf-color-purple);
}
.color-red {
  color: var(--cf-color-red);
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.f-40 {
  font-size: 26px;
}

.f-35 {
  font-size: 22px;
}

.f-30 {
  font-size: 20px;
}

.f-25 {
  font-size: 18px;
}

.f-20 {
  font-size: 16px;
}

.f-18 {
  font-size: 15px;
}

.f-16 {
  font-size: 14px;
}

.f-14 {
  font-size: 14px;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

/* .header-bar .border-right::after {
    content: "";
    position: absolute;
    right: 8%;
    width: 2px;
    height: 30px;
    background: #5a5a5a;
    top: -2px;
} */

.custom-font-size .f-18.modal-title.h4 {
  font-size: 18px;
}
.custom-font-size h4 {
  font-size: 18px;
}
.custom-font-size p {
  font-size: 16px;
}
.top-bar {
  background: #fff;
  padding: 10px 0px;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
}

.top-bar .logos img {
  height: 40px;
}

.top-bar .logos img.h-26 {
  height: 26px;
}

span.name {
  display: inline-block;

  color: #fff;
  font-weight: 500;
  background: #63bc62;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
}

.bar-bottom-details ul {
  padding-left: 15px;
  font-size: 12px;
  line-height: 20px;
  columns: 2;
  margin-bottom: 0px;
}

.bar-bottom-details.green ul li::marker {
  color: #28a745;
}

.bar-bottom-details.orange ul li::marker {
  color: #ffa800;
}

.bar-bottom-details.purple ul li::marker {
  color: #9c06fe;
}

.bar-bottom-details.blue ul li::marker {
  color: #28a745;
}

.bar-bottom-details {
  position: absolute;
  width: calc(100% + 2px);
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 5px 10px;
  border: 1px solid #cee2fe;
  z-index: 9999;
  display: none;
  left: -1px;
  border-top: 0;
}

.box-h:hover .bar-bottom-details {
  display: block;
}

.box-h {
  padding: 5px 10px;
  border: 1px solid transparent;
  background: transparent;
  border-bottom: 0px !important;
}

.box-h:hover {
  padding: 5px 10px;
  /* border: 1px solid #CEE2FE; */
  background: #fff;
  /* border-radius: 10px 10px 0px 0px; */
  border-bottom: 0px !important;
}

.box-h:hover::after {
  display: none;
}

.box-h:hover .fa {
  opacity: 0;
}

.col-bg {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
}

/* .shadow-box { box-shadow: -8px 12px 18px 0px #E9EFFE;  border-radius: 10px; padding: 10px 15px;border: 1px solid #DEEBFD;} */
.shadow-box {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
}

/* ----- change Shadow Box css by sumit on 16may ---------------------- */
.pipe {
  color: #a3a3a3;
  font-weight: 100;
}

.fields .form-control {
  height: 35px;
}

.mt-minus-25 {
  margin-top: -20px;
}

.search-box {
  background-color: #eee !important;
  border-radius: 5px;
  border: 1px solid #828282 !important;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
  color: #4b4d4d;
  height: 35px;
}

.search-box::-webkit-input-placeholder {
  color: #4b4d4d !important;
  font-size: 12px !important;
}

.search-box:-ms-input-placeholder {
  color: #4b4d4d !important;
  font-size: 12px !important;
}

.search-box::placeholder {
  color: #4b4d4d !important;
  font-size: 12px !important;
}

.nav-pills li button {
  color: #333;
  width: auto !important;
  text-shadow: none !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  padding: 8px 10px !important;
  border: 1px solid #e1e1e1 !important;
  background: transparent !important;
  border-radius: 8px !important;
}

.nav-pills li button.active {
  background: #0e8bff !important;
  color: #fff !important;
}

.nav-pills li button.active img {
  filter: invert(1);
  width: auto;
}

.round-border {
  border: 1px solid #d9d9d9;
  border-radius: 18px;
  padding: 10px 15px;
}

.round-border p {
  margin: 0px;
}

.alert {
  padding: 10px 15px;
  border-radius: 18px;
  margin: 0;
}

.progress {
  width: 80%;
  height: 6px;
  border-radius: 5px;
}

.border-right::after {
  content: "";
  position: absolute;
  background-color: #a3a3a3;
  right: 0;
  width: 1px;
  height: 70%;
}

.ml-e > div + div {
  margin-left: 10px;
}

.border-right::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 40px;
  background: #a3a3a3;
  top: 25%;
}

.shadow-box .ui-shadow-inset {
  box-shadow: none !important;
  border: 0 !important;
  background: transparent !important;
}

.ui-content {
  padding: 0 !important;
}

.timeline .nav-pills li button.active {
  background: #28a745 !important;
}

/* =====================Date============================== */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.fields .form-control {
  height: 15px;
  padding: 0;
}

.chrt {
  margin-top: -15px;
}

.h-250 {
  height: 220px;
}
body{overflow-y: hidden !important;}

/* =================Range Slider============================ */
html
  > body
  .ui-rangeslider
  .ui-rangeslider-sliders
  .ui-slider-track:first-child,
.ui-slider-track {
  height: 8px !important;
}

.ui-slider-track {
  box-shadow: none !important;
  background-color: #e4e4e4 !important;
  border-color: #e4e4e4 !important;
}

.ui-slider-track .ui-btn.ui-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  background-color: #28a745;
  border: 0px;
  margin: -12px 0 0 -15px !important;
}

.ui-slider-bg.ui-btn-active {
  background: linear-gradient(90deg, #28a745 15.54%, #a8f74d 100%);
}

.ui-page-theme-a .ui-slider-handle:hover {
  background: #28a745 !important;
}

.ui-slider-handle:nth-child(3) {
  background: #a8f74d !important;
}

.ui-rangeslider .ui-slider-track:first-child .ui-slider-bg {
  display: block !important;
}

/* =============================================== */

.i img {
  height: 25px;
  width: auto;
}

.plr-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.ui-input-text,
.ui-input-search {
  margin: 0 0 !important;
}

.ui-mobile .ui-page-active {
  overflow: hidden !important;
}

.db-grid .col-md-3 {
  margin-bottom: 0px;
}

.db-grid {
  max-height: calc(100vh - 250px);
  overflow: auto;
  /* min-height: 580px; */
  min-height: calc(100vh - 250px);
}

@media screen and (min-height: 1200px) {
  .db-grid {
    max-height: 100% !important;
  }
}

@media (min-width: 768px) {
  .db-grid > .col-md-2 {
    width: 20%;
  }
}

.db-grid::-webkit-scrollbar {
  width: 5px;
}

.db-grid::-webkit-scrollbar-track {
  background: #deebfd;
  border-radius: 30px;
}

.db-grid::-webkit-scrollbar-thumb {
  background: #a7c6e4;
  border-radius: 30px;
}

/* ============================================ */
.av-btn {
  text-shadow: none;
  cursor: pointer;
  color: #fff !important;
  font-weight: 400 !important;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  min-width: 80px;
  text-align: center;
  text-decoration: none;
}

.green-btn {
  background: #5e8e28;
}

.blue-btn {
  background: #458fff;
}

.purple-btn {
  background: #9e00ff;
}

.small-input .ui-input-text {
  width: auto;
  display: inline-block;
}

.small-input .ui-input-text .form-control {
  height: 25px !important;
  width: 40px;
  border: 1px solid #6d6d6d;
  min-height: auto;
  text-align: center;
  line-height: 25px !important;
  font-size: 14px;
}

.h-line {
  height: 160px;
  background: #d1d1d1;
  width: 1px;
  margin-top: 20px;
  margin: 0 auto;
  margin-top: 20px;
}

.form-control[readonly] {
  pointer-events: none;
}

#update {
  display: none;
}

.form-check-input[type="checkbox"] {
  display: none;
}

label.form-check-label.ui-btn {
  padding: 4px 10px;
  padding-left: 2.5em;
}

button.ui-btn {
  width: auto;
}

button.ui-btn {
  width: auto !important;
  border: 0;
  box-shadow: none;
}

.submit-btn {
  background: #458fff !important;
  text-shadow: none !important;
  color: #fff !important;
  text-transform: capitalize;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

textarea {
  text-shadow: none !important;
  box-shadow: none !important;
}

.apexcharts-legend-series {
  display: block !important;
  width: 100%;
}

.redborder {
  border: 1px solid #ff4560;
}

.textdanger {
  color: #ff4560;
  font-weight: 700;
}

@media (max-width: 1440px) and (min-width: 1300px) {
  .f-40 {
    font-size: 20px;
  }

  .i img {
    height: 16px;
  }

  .f-16 {
    font-size: 13px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 15px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px "Sailec";
    margin-bottom: 8px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 12px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 405px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 10px !important;
  }

  .modal-content table td {
    font-size: 10px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .tooltip {
    font-size: 12px;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }
}

@media (max-width: 1299px) and (min-width: 1153px) {
  .tooltip {
    font-size: 12px;
  }

  .f-40 {
    font-size: 13px;
  }

  .i img {
    height: 14px;
  }

  .f-16 {
    font-size: 11px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 13px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px "Sailec";
    margin-bottom: 10px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 480px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 9px !important;
  }

  table td {
    font-size: 12px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }
}

/* --------------------Added by sumit on 16may---------------------------------- */

@media (max-width: 1152px) and (min-width: 1024px) {
  .tooltip {
    font-size: 12px;
  }

  .f-40 {
    font-size: 13px;
  }

  .i img {
    height: 14px;
  }

  .f-16 {
    font-size: 11px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 13px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    margin: 6px 2px !important;
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px "Sailec";
    margin-bottom: 10px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 480px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 9px !important;
  }

  table td {
    font-size: 11px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }

  .hd th {
    font-size: 11px !important;
  }
}

@media (min-width: 1024px) {
  .row.db-grid .col-md-3 {
    /* width: 20%; */
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }

  .col-md-7.ps-2 {
    width: 100%;
    padding-left: 0 !important;
    margin-top: 7px;
  }

  .col-4.h-100 {
    margin-top: 4px;
  }

  .av-btn {
    padding: 8px 6px;
  }

  .av-btn .f-14 {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .alert {
    margin-top: 2px;
  }
}

@media (max-width: 1440px) {
  .px .ag-chart-wrapper {
    max-height: 230px !important;
  }
}

/* ======================================Fonts========================================================================== */
@font-face {
  font-family: "Sailec";
  src: url("./assets/fonts/Sailec-Black.woff2") format("woff2"),
    url("./assets/fonts/Sailec-Black.woff") format("woff"),
    url("./assets/fonts/Sailec-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sailec Hairline";
  src: url("./assets/fonts/SailecHairline.woff2") format("woff2"),
    url("./assets/fonts/SailecHairline.woff") format("woff"),
    url("./assets/fonts/SailecHairline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sailec";
  src: url("./assets/fonts/Sailec-Bold.woff2") format("woff2"),
    url("./assets/fonts/Sailec-Bold.woff") format("woff"),
    url("./assets/fonts/Sailec-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sailec";
  src: url("./assets/fonts/Sailec-Light.woff2") format("woff2"),
    url("./assets/fonts/Sailec-Light.woff") format("woff"),
    url("./assets/fonts/Sailec-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sailec";
  src: url("./assets/fonts/Sailec-Medium.woff2") format("woff2"),
    url("./assets/fonts/Sailec-Medium.woff") format("woff"),
    url("./assets/fonts/Sailec-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sailec";
  src: url("./assets/fonts/Sailec.woff2") format("woff2"),
    url("./assets/fonts/Sailec.woff") format("woff"),
    url("./assets/fonts/Sailec.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sailec";
  src: url("./assets/fonts/Sailec-Thin.woff2") format("woff2"),
    url("./assets/fonts/Sailec-Thin.woff") format("woff"),
    url("./assets/fonts/Sailec-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37 Moon";
  src: url("./assets/fonts/F37Moon-Black.woff2") format("woff2"),
    url("./assets/fonts/F37Moon-Black.woff") format("woff"),
    url("./assets/fonts/F37Moon-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37 Moon";
  src: url("./assets/fonts/F37Moon-Bold.woff2") format("woff2"),
    url("./assets/fonts/F37Moon-Bold.woff") format("woff"),
    url("./assets/fonts/F37Moon-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37 Moon Demi";
  src: url("./assets/fonts/F37Moon-Demi.woff2") format("woff2"),
    url("./assets/fonts/F37Moon-Demi.woff") format("woff"),
    url("./assets/fonts/F37Moon-Demi.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37 Moon";
  src: url("./assets/fonts/F37Moon-Light.woff2") format("woff2"),
    url("./assets/fonts/F37Moon-Light.woff") format("woff"),
    url("./assets/fonts/F37Moon-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37 Moon";
  src: url("./assets/fonts/F37Moon-Regular.woff2") format("woff2"),
    url("./assets/fonts/F37Moon-Regular.woff") format("woff"),
    url("./assets/fonts/F37Moon-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "F37 Moon";
  src: url("./assets/fonts/F37Moon-Thin.woff2") format("woff2"),
    url("./assets/fonts/F37Moon-Thin.woff") format("woff"),
    url("./assets/fonts/F37Moon-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

.db-grid .ag-chart-wrapper {
  max-height: 250px;
}

.mb-minus-10 {
  margin-bottom: -15px;
}

.width-100p {
  width: 55px;
}

td.f-row.minheight60 {
  word-break: break-word;
  text-align: left !important;
  padding-right: 3px;
}

.spacing-table td:nth-child(2),
.spacing-table th:nth-child(2) {
  width: 110px;
}

.spacing-table td:nth-child(3),
.spacing-table th:nth-child(3) {
  width: 110px;
}

.db-grid .shadow-box {
  overflow: hidden;
}

.twopx {
  width: calc(100% - 150px);
  margin-left: -5px;
}

.details .twopx {
  width: calc(100% + 40px);
  /* margin-left: -20px; */
  /* background-color: red; */
}

.modal .sidebar--close {
  display: none;
}

.modal .width-in {
  width: 100% !important;
  margin: 0 !important;
  padding: 7px 15px !important;
  margin-left: 7px !important;
}

.modal .copyright-footer {
  display: none;
}

.bg-transparent.btn-close {
  background: transparent;
  outline: none;
}

.select-style.css-b62m3t-container {
  outline: none;
  /* border: 1px solid gray; */
  border-radius: 5px;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background: transparent !important;
  height: 35px !important;
  min-height: 35px !important;

  border-color: #828282 !important;
  border-radius: 5px !important;

  font-size: 12px !important;
}

.hIgppe,
.kpaCiq {
  background-color: #28a745 !important;
  padding: 0px 15px !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  height: 35px !important;
  line-height: 35px !important;
}

.select-style.css-b62m3t-container {
  font-size: 12px !important;
}

.hIgppe:hover,
.kpaCiq:hover {
  background-color: #fff !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 6px !important;
}

.css-t3ipsp-control {
  background-color: transparent !important;
}

.css-1nmdiq5-menu {
  overflow-x: hidden !important;
  /* width: 70px !important; */
  /* overflow-y: scroll !important; */
}

.form-select:disabled {
  background-color: #e3e3e3 !important;
  border: 1px solid #d9d9d9 !important;
}

.apexcharts-legend.apx-legend-position-bottom,
.apexcharts-legend.apx-legend-position-top {
  flex-wrap: nowrap !important;
}

.custom-close-button {
  color: #ff0000;
  /* Red color */
  border: none;
  width: 30px;
  /* Set the desired width and height */
  height: 30px;
  font-size: 20px;
  /* Adjust the font size as needed */
  line-height: 1;
  /* Center the icon vertically */
  cursor: pointer;
  background: transparent;
}

.width-in .custom-close-button {
  height: auto;
}

.custom-close-button:hover {
  color: #cc0000;

  /* Red color on hover */
}
.arrow_box {
  padding: 5px;
}

.modal-content .custom-font-size h4 {
  display: flex;
  align-items: center;
}
.modal-content .custom-font-size h4 img {
  width: 18px;
  margin-right: 7px;
}

.selected-active-row {
  background-color: rgba(0, 0, 0, 0.075);
}

tbody.crossi tr td {
  position: relative;
}
tbody.crossi tr td .custom-pos {
  position: absolute;
  margin-left: 80px;
}

.sumary-text {
  font-size: 13px !important;
  margin-top: 5px;
  white-space: nowrap;
}
table thead tr th .ms-2 img {
  width: 8px;
  vertical-align: baseline;
}
.realtime-details {
  padding: 0;
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  list-style: none;
  line-height: 2.2;
  border-left: 1px solid #bdbdbd;
  padding-left: 10px;
  margin-left: -20px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-top: 25px;
}

.width-160 {
  width: 140px;
}
@media (max-width: 1441px) and (min-width: 767px) {
  .width-in {
    width: calc(100% - 150px);
    padding-left: 10px;
    padding-right: 10px;
  }
  .fs-6 {
    font-size: 14px !important;
}
}
.card-title {
  /* color: #ffffff; */
  margin-bottom: 1.125rem;
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: 500;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  align-items: center;

  & a {
    padding: 10px;
    border: 1px solid transparent;
    color: #505050;

    &:hover {
      color: white !important;
      border: 1px solid #0f1015;
      background-color: #0f1015;
    }
  }
}

.paginationLink {
  font-weight: bold;

  &:hover {
    background-color: red;
  }
}

.paginationActive {
  & a {
    color: #505050;
    border: 1px solid #28a745;
    background: linear-gradient(to bottom, #28a745 0, #28a745 100%);
  }
}

.paginationDisabled {
  & a {
    color: #cacaca;
  }
}

@media (max-width: 767px) {
  .ant-menu-item {
    height: auto !important;
    display: block !important;
    text-align: center !important;
    padding: 0 !important;
  }
  .ant-layout-sider {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }

  .ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;
  }
  .ant-menu-item .ant-menu-item-icon {
    margin: 0 auto !important;
  }
  .ant-menu-item .ant-menu-title-content {
    margin: 0 !important;
    opacity: 1 !important;
  }
  main.ant-layout-content {
    padding: 10px !important;
  }
  .dropdown.l-menu h5 {
    display: none;
  }
  .trigger {
    padding: 0 10px;
  }
  div#dropdownMenuLink {
    display: none;
  }
  .mobile-wrap {
    flex-wrap: wrap;
  }
  .mobile-w-auto{width: auto !important;}
  .mobile-w-100{width: 100% !important;}
  .mobile-half-col .col{min-width: 50%; width: 50%;}
}
.bright img {
  /* filter: brightness(10); */
  height: 11px;
}
.dropdown-toggle::after {
  display: none;
}
.btn:focus {
  box-shadow: none !important;
}
.dropdown-item img {
  margin-right: 15px;
}
.hd {
  height: 40px;
  line-height: 30px;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0;
  width: 1.2em;
  height: 1.2em;
}
/* .dataTables_filter input {
  outline: none !important;
}
.filter-table th:before {
  bottom: 50%;
  content: "\2BC5";
}
.filter-table th:after {
  content: "\2BC6";
  top: 50%;
}
.filter-table th:after,
.filter-table th:before {
  color: #fff;
  display: block;
  font-size: 12px;
  line-height: 8px;
  opacity: 0.3;
  position: absolute;
  right: 10px;
} */

.ant-layout-sider-children
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  height: calc(100vh - 90px);
  overflow: auto;
  min-height: 300px;
}
.ant-layout-sider-children ul.ant-menu::-webkit-scrollbar {
  width: 7px;
}
.ant-layout-sider-children ul.ant-menu::-webkit-scrollbar-thumb {
  background: #848689;
}
.ant-layout-sider-children ul.ant-menu::-webkit-scrollbar-track {
  /* background: #3b3b3b; */
  border-radius: 30px;
}
.ant-layout-sider-children ul.ant-menu::-webkit-scrollbar-thumb {
  border-radius: 30px;
}
.custom-normal-design .css-13cymwt-control,
.custom-normal-design .css-t3ipsp-control {
  height: 43px !important;
  min-height: 43px !important;
  border-radius: 2px !important;
}
.file-activity-table tr th:nth-child(3), .file-activity-table tr td:nth-child(3) {
  max-width: 220px;
}
.file-activity-table tr th:nth-child(5), .file-activity-table tr td:nth-child(5) {
  max-width: 100px;width: 100px;
}
.file-activity-table tr th:nth-child(6), .file-activity-table tr td:nth-child(6) {
  max-width: 144px;width: 144px;
}
.file-activity-table tr th:nth-child(1), .file-activity-table tr td:nth-child(1) {
  max-width: 40px;width: 40px;
}
.file-activity-table tr th:nth-child(2), .file-activity-table tr td:nth-child(2) {
  max-width: 160px;width: 160px;
}
@media (max-width: 767px) {
  .jsePbI {
    flex-wrap: wrap;
  }
  .jKnUfW,
  .ftpquH {
    width: 100% !important;
  }
  .kZSHyX {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  .ihuXMY {
    width: 47%;
  }
  .gzLRTF {
    height: auto;
    width: 100%;
  }
}
.jKnUfW {
  padding-bottom: 0px !important;
}
.mixed-chart > div {
  height: 100%;
}
.jKSUUS {
  font-size: 1.5rem !important;
  padding: 1rem 0.5rem 5px;
}
.subheading {
  padding: 0rem 0.5rem 8px;
  color: #7a7a7a;
}
.flx-r .sc-cwHptR.jKSUUS {
  display: flex;
  justify-content: space-between;
}
.filter-btn {
  display: inline-flex;
}
.filter-btn span {
  font-size: 1rem;
  padding: 2px 5px;
  margin: 2px;
  background: #a0a0a0;
  cursor: pointer;
  color: #fff;
  /* line-height: 27px; */
}
.filter-btn span.active {
  background: #5e5858;
}

.filter-table th {
white-space: nowrap;
}
.react-datepicker__close-icon::after {padding: 0px;
  font-size: 12px;
  line-height: 14px;display: block;
}
a.paginationLink {
  display: block;
}
.wx-10{width: 10%}
.dataTables_paginate{margin-top: 15px;}
.dataTables_info{margin-top: 15px;}
.wraping-table th{white-space: normal; text-align: center;}
.card .card-body .wraping-table .form-control, .card .card-body .wraping-table .h-30  {height: 30px;padding: 0 10px;}
.searchWrapper { border-radius: 0px !important; }
.multiSelectContainer ul { border: 0px transparent;}
.css-xmdu28-menu{z-index: 999999;position: fixed;}
.css-1n6sfyn-MenuList{padding-top: 20px !important;}
.css-kbwd40-option{padding: 5px 10px !important;}
.css-1lw84mo-option{padding: 5px 10px !important;}
.css-1f8i51t-menu {
  padding-bottom: 20px !important;}

.not-allowedx {
  pointer-events: auto ! important;
  cursor: not-allowed ! important;
}

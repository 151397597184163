.ft-table-v .center-border {
    border-left: 1px solid #2C2E33 !important;
    border-right: 1px solid #2C2E33 !important;
}
.ft-table-v.table th {font-size: 16px;}
.big-font.f-18 {
    font-size: 24px !important;
    line-height: 1;
}
.ft-table-v.table th, .ft-table-v.table td{vertical-align: middle;}
small.f-12 {
    font-size: 11px !important;
}
@media (max-width: 1600px) and (min-width: 1441px){
.ft-table-v.table td{
    font-size: 14px !important;
}
.ft-table-v.table td.big-font.f-18 {
    font-size: 24px !important;
}
}
.modal-dialog.modal-md {
    max-width: 900px;
   
}
.f-14.modal-body p{font-size: 14px; margin-bottom: 6px;}
.text-indent-15 p{margin-left: 25px;}
.text-indent-15 p.text-indent-30{margin-left: 45px;}


.card .card-body .form-group label {
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: top;
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  .card .card-body .form-control,
  .card .card-body .form-select,
  .asColorPicker-input,
  .dataTables_wrapper select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-search__field,
  .typeahead,
  .tt-query,
  .tt-hint {
    border: 1px solid #2c2e33;
    /* height: calc(2.25rem + 2px); */
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    background-color: #2a3038 !important;
    border-radius: 2px;
    color: #ffffff !important;
  }
  .card .card-body .form-group {
    margin-bottom: 1rem;
  }
  .white-text-dark-mode,
  .modal-title,
  .form-label {
    color: #f3f3f3 !important;
  }
  .form-select.white,
  .col .form-control {
    color: #fff !important;
  }
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    color: #fff !important;
  }
  .card .card-body .form-select {
    background-image: url(../../assets/img/down-arrow.png);
  }
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  .checkbox-group {
    display: flex;
    flex-wrap: wrap; /* Allow checkboxes to wrap to the next row */
    gap: 16px; /* Adjust the gap between checkboxes */
  }
  
  /* Style for each checkbox item */
  .checkbox-item {
    flex: 0 0 calc(25% - 16px); /* Each checkbox takes up 25% of the container width with a gap of 16px */
    box-sizing: border-box;
  }
  
  .custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #3498db;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
    vertical-align: middle;
  }
  
  .custom-checkbox:checked {
    background-color: rgb(0, 144, 231);
    border-color: rgb(0, 144, 231);
  }
  
  .custom-checkbox:checked::before {
    content: "\2713";
    display: block;
    text-align: center;
    line-height: 13px;
    color: #000;
    font-weight: bold;
  }
  
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker-wrapper input {
    width: 100%;
    background: rgb(42, 48, 56);
    color: white !important;
    border: none !important;
  }
  .box {
    max-height: 300px;
    min-height: 200px;
    overflow: auto;
  }
  .box::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  .box::-webkit-scrollbar-track {
    background: #deebfd;
    border-radius: 30px;
  }
  
  .box::-webkit-scrollbar-thumb {
    background: rgb(0, 144, 231);
    border-radius: 30px;
  }
  .close-cost-center {
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
  }
  .red-checkbox .custom-checkbox {
    border: 2px solid #0bd420;
  }
  .red-checkbox .custom-checkbox:checked {
    background-color: rgb(0 231 13);
  }
  


  .custom-swal {
    z-index: 9999 !important;
  }

  .th-cursor {
    cursor: auto !important;
  }
#range-slider-yellow {
    background-color: #ccc;
}
  
  #range-slider-yellow .range-slider__range {
    background: linear-gradient(90deg, #28A745 15.54%, #A8F74D 100%);
    transition: height 0.3s;
  }
  
  #range-slider-yellow .range-slider__thumb {
    background: #28A745;
    transition: transform 0.3s;
  }
  
  #range-slider-yellow .range-slider__thumb[data-active] {
    transform: translate(-50%, -50%) scale(1.25);
  }
  
  #range-slider-yellow .range-slider__range[data-active] {
    height: 16px;
  }

.fitcontent{flex-basis: fit-content;}
.sliderfilder .input-range__slider{background: #28A745;}
.sliderfilder .input-range__track--active{background: linear-gradient(90deg, #28A745 15.54%, #A8F74D 100%);}
.sliderfilder .input-range__track{height: 0.5rem;}
.sliderfilder .input-range__slider{width: 1.4rem; height: 1.4rem;margin-top: -0.95rem; border:0;}
.sliderfilder .input-range__label-container{left: auto;}
.sliderfilder .input-range__label--value{top: -2.2rem;}

.arrowdes{padding-top: 6px;}
.arrowdes:hover{background-color: #eee;}
.arrowdes svg{color: #777;}

.input-range__label.input-range__label--min,
.input-range__label.input-range__label--max{
  display: none;
}

.leftrightinput{text-align: center;padding: 0; width: 40px; height: 34px;position: relative; top: -8px;z-index: -1;}
/* Chrome, Safari, Edge, Opera */
.leftrightinput::-webkit-outer-spin-button,
.leftrightinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.leftrightinput[type=number] {
  -moz-appearance: textfield;
}
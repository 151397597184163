h3{color:#000;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    }
   .sp1 {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0em;
        text-align: left;margin-bottom: 5px;
    }
    .sp2{font-family: Sailec;
        font-size: 20px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        }
        .ind-table th{font-weight: 500;}
        .ind-table th{font-size: 12px;}
        .exceptions-table a{text-decoration: none;color: var(--bs-table-color);display: block;}
        .exceptions-table.tb2 td {
            padding: 7px 2px;
            vertical-align: middle;
        }
        .scroll-table{position: relative;}
       .scroll-table tbody {
            display: block;
            /* max-height: 555px; */
            max-height: calc(100vh - 472px);
            overflow: auto;
        }
       .scroll-table tbody::-webkit-scrollbar {width: 5px;}
       .scroll-table tbody::-webkit-scrollbar-track {background: #DEEBFD;border-radius: 30px;}
       .scroll-table tbody::-webkit-scrollbar-thumb {  background: #a7c6e4;border-radius: 30px;}
       .scroll-table thead, .scroll-table tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;/* even columns width , fix width of table too*/
        }
       /* .scroll-table thead {
            width: calc( 100% - 1em )
        } */
        /* .scroll-table tbody tr:last-child{
            
            position: absolute;
        } */
        .tb2 tbody tr.bt
        {border-top :1px solid #dbdbdb !important}
        /* .h-454{height: 545px;} */
        .h-half{height: 50%;background: #FFF;}
       .border-bottom-5 {border-bottom: 0.25rem solid #eeeeee;     }
       .border-top-5 {border-top: 0.25rem solid #eeeeee}
       .vertical-align-middle{vertical-align: middle;}
       .font-15{font-size: 15px !important;}
       .w-auto-col .d-flex.align-items-center {
        width: auto;
    }
    .tb2 tbody.ftr tr:nth-last-child(1), .tb2 tbody.ftr tr:nth-last-child(1) td {
        width: calc(100% - 4px);
    }
    .mt-minus-5{margin-top: -5px !important;}
    .apexcharts-canvas {
        height: 100% !important;
        width: 100% !important;
    }
    .yh .apexcharts-canvas {
        height: 100% !important;
        /* padding-top: 20px !important; */
    }
    div#chart {
        height: 90% !important;
    }
    div#chart.yh {
        height: 100% !important;padding-top: 20px;
    }
    .height-80v#chart{ height: 75% !important;}
    .border-top-5.h-half {
        min-height: auto !important;
    }
    .h-454{
       /* min-height: calc(65vh + 16px); */
        min-height: calc(100vh - 317px)
    }
    .apexcharts-yaxis, .apexcharts-inner{padding-top: 40px !important;}
    @media (max-width: 1440px) and (min-width: 1300px)
    {
    .h-454 {
        /* min-height: calc(65vh + 16px); */
        min-height: calc(100vh - 304px);
    }
}